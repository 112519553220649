window.getVacancies = function () {

    $.ajax({
        url: '/get-vacancies',
        dataType: 'json',
        data: {
            type: 'active'
        },
        type: 'POST',
        success: function(json) {
            // console.log(json);
            // $.each(json.data, function(key, val) {
            //     window.updateStats(key, val);
            // });
            renderVacancy(json.data);
        }
    });
}

function renderVacancy(data) {
    let wrap = $('div.career-items');

    $.each(data, function (key, val) {
        prepareData(val)
        wrap.append(makeVacancy(val));
    });
}

function makeVacancy(val) {
    return '<div class="career-item-wrap-outside">' +
                '<div class="career-item">' +
                    '<div class="title">' + val.title +'</div>' +
                    '<div class="description">' + val.description + '</div>' +
                    '<div class="btn-wrap">' +
                    '<a href="career/' + val.id + '" class="animate-btn btn_fill-blue white-color">Смотреть описание</a>' +
                    '<button class="animate-btn btn_blue-border blue-color">Откликнуться</button>' +
                    '</div>' +
                '</div>' +
            '<div>';
}