import Swiper from 'swiper/bundle';

const ideaSlider = document.querySelector('.swiper-container.ideas')

if (ideaSlider) {
  var swiper = new Swiper('.swiper-container.ideas', {
    slidesPerView: 'auto',
    spaceBetween: 5,
  });
}

const finishIdeaSlider = document.querySelector('.swiper-container.fihished-idea')

if (finishIdeaSlider) {
  var swiper = new Swiper('.swiper-container.fihished-idea', {
    slidesPerView: 'auto',
    spaceBetween: 5,
  });
}
