require('./bootstrap');

import 'bootstrap';

import $ from 'jquery';
require('jquery.maskedinput/src/jquery.maskedinput.js');
window.$ = window.jQuery = $;
import Chart from 'chart.js/auto';
window.Chart = Chart;
import { Fancybox } from '@fancyapps/ui';
window.Fancybox = Fancybox;
let momentTz = require('moment-timezone');
window.momentTz = momentTz;

require('./callModal');
require('./getStats');
require('./getRecom');
require('./getArticles');
require('./getDocuments');
require('./getEvents');
require('./getEmployees');
require('./getVacancies');
require('./getPartners');
require('./getBranches');
require('./getVar');
require('./ready');

require('./components/dropdown');
require('./components/header');
require('./components/ideaSlider');
require('./components/swiper');
require('./components/menu');
// require('./components/select');