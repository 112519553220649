window.getEvents = function (data) {
    let wrap = document.querySelector('.video-items');
    let loader = document.querySelector('.loader-wrap');

    $.ajax({
        url: '/get-events',
        dataType: 'json',
        type: 'POST',
        data: {
            data: data, // { status: 'all' || 'next' || 'last' ; type: 'all' || 'offline' || 'online' }
        },
        success: function (json) {
            console.log(json);
            if (data.type === 'online') {
                processingVideo(json.data);
            } else {
                processingEvents(json.data);
            }

            if (wrap) {
                wrap.classList.add('loaded');
            }
            if (loader) {
                loader.hidden = true;
            }
        }
    });
}

function processingEvents(data) {
    let $next = $('div.activity-next'),
        $past = $('div.activity-past'),
        $slider = $('.swiper-wrapper'),
        storage = $('input.env').data().storage,
        makeSlider = (document.location.pathname === '/');

    $.each(data, function (key, val) {
        prepareData(val);
        let html = makeEvents(val, storage);
        if (makeSlider) {
            $slider.append(makeSlide(html));
        } else if (val.date_start > momentTz()) {
            $next.append(html);
        } else {
            $past.append(html);
        }
    });
    if (makeSlider) {
        window.startSwiper();
    }
}

function processingVideo(data) {
    let $video = $('div.video-items');

    $.each(data, function (key, val) {
        prepareData(val);
        let html = makeVideo(val);
        $video.append(html);
    });
}

function makeEvents(val, storage) {
    let modal = (val.date_start > momentTz()) ? 'data-bs-toggle="modal" data-bs-target="#modal1"' : '';
    return '<div class="activity-item-wrap-outside"><div class="activity-item">' +
        '<div class="event-slider__wrap">' +
            '<div class="event-slider__img">' +
                '<img src="' + storage + val.image + '" alt="Здесь могла бы быть картинка :-(" onError="this.src=\'images/error/event.jpg\'">' +
            '</div>' +
            '<div class="event-slider__info">' +
                '<div class="event-slider__title datetime-utc" data-date="' + val.date_start.toISOString() + '">' + val.date_start.format('DD.MM.YYYY HH:mm') + '</div>' +
                '<div class="event-slider__desc">' + val.name + '</div>' +
                '<button class="event-slider__btn animate-btn btn_fill-blue white-color" ' + modal + '> Принять участие </button>' +
        '</div></div>' +
        '<div class="event-slider__format">' + val.type + '</div>' +
        '</div></div>'
        ;
}

function makeVideo(val) {
    let href, img, key, index;

    if (val.href.match(/\?/)) {
        key = val.href.split('?')[1];
        key = key.slice(key.indexOf('=') + 1);
    } else if (index = val.href.match(/\.be\//)) {
        key = val.href.slice(index.index + 4);
    }

    href = 'https://www.youtube.com/embed/' + key;
    img = 'https://img.youtube.com/vi/' + key + '/hqdefault.jpg'; //mqdefault.jpg, default.jpg, hqdefault.jpg, sddefault.jpg, maxresdefault.jpg

    return '<div class="video-item-outside">' +
        '<div class="video-item">' +
            '<div href="' + href + '?autoplay=1" class="link-block" data-fancybox="responsive" >' +
        // '<iframe class="video-yo" src="' + href + '" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' +
                '<img src="' + img + '">' +
                '<div class="title">' +
                '<div>' + val.name + '</div>' +
                '<div class="description">' + val.description + '</div>' +
            '</div>' +
        '<div class="date datetime-utc">' + val.date_start.format('DD.MM.YYYY HH:mm') + '</div>' +
        '</div></div>';
}

function makeSlide(html) {
    return '<div class="swiper-slide">' + html + '</div>';
}