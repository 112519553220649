const menuIcon = document.querySelector('.menu-trigger')
const menuWrap = document.querySelector('.header-menu-wrap')
const descTigger = document.querySelector('.for-mobile-trigger')
const descMobile = document.querySelector('.for-mobile')


menuIcon.addEventListener('click', ()=>{
 menuWrap.classList.toggle('show-menu')
})


descTigger.addEventListener('click', ()=>{
  descMobile.classList.toggle('show')
})
