import Swiper from 'swiper/bundle';

window.startSwiper = function() {
    new Swiper('.event-slider .swiper-container', {
        navigation: {
            nextEl: '.event-slider .swiper-button-next',
            prevEl: '.event-slider .swiper-button-prev',
        },
        pagination: {
            el: '.event-slider .swiper-pagination',
            clickable: true,
        },
        on: {
            init: function () {
                console.log('swiper initialized');
            },
        },
    });
}