let mediaQuery = x => {

    if (!x.matches) {
        $('.mydropdownjQuery').hover(function () {
                let $this = $(this);
                $this.addClass('show');
                $this.find('.dropdown-menu').addClass('show');
            },
            function () {
                let $this = $(this);
                $this.removeClass('show');
                $this.find('.dropdown-menu').removeClass('show');
            });
    }

    if (x.matches) {
        $('.dropdown-menu').addClass('show');
    }

    $('.lang-switch .mydropdownjQuery').click(function () {
        let $this = $(this);
        $this.addClass('show');
        $this.find('.dropdown-menu').toggleClass('show');
    })
}

let x = window.matchMedia("(max-width: 992px)")
mediaQuery(x)
x.addListener(mediaQuery)
