window.getPartners = function (type) {

    $.ajax({
        url: '/get-partners',
        dataType: 'json',
        type: 'POST',
        data: {
            // type: type, // type: 'all','next','last'
        },
        success: function(json) {
            console.log(json);
            processingPartners(json.data);
        }
    });
}

function processingPartners(data) {
    let $container = $('div.partners__wrap-item'),
        key = 0;

    $.each(data, function(name, val) {
        let html = makePartner(key, val);
        $container.append(html);
        key++;
    });
}

function makePartner(key, val) {
    let revert = (key % 2) ? 'revert' : '' ;
    return '<div class="partners__item ' + revert + '">' +
        '<div class="partners__img">' +
            '<img src="' + val.logo + '" alt="">' +
        '</div>' +
        '<div class="partners__info">' +
            '<div>' +
                '<div class="title">' +
                    '<h2 class="mb20">' + val.title + '</h2>' +
                '</div>' +
                '<div class="description">' + val.description + '</div>' +
            '</div>' +
            '<div class="link-wrap">' +
                '<a class="animate-btn btn_fill-blue white-color" href="' + val.about + '">Перейти на сайт</a>' +
            '</div>' +
        '</div></div>'
        ;
}