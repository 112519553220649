window.getRecom = function (type) {

    $.ajax({
        url: '/get-recommendations',
        dataType: 'json',
        data: {
            type: type //,'next','last'
        },
        type: 'POST',
        success: function (json) {
            // console.log(json);
            renderRecom(json.data);
        }
    });
}

function renderRecom(data) {
    let wrap = $('div.ideas-wrap'),
        $slider = $('.swiper-wrapper.investidea-mobile'),
        storage = $('input.env').data().storage;

    $.each(data, function (key, val) {
        prepareData(val);
        let html = makeRecom(val,storage);
        wrap.append(makeRecom(val));
        $slider.append(makeSlide(html));
    });
}

function makeSlide(html) {
    return '<div class="swiper-slide">' + html + '</div>';
}

function makeRecom(val,storage) {
    return '<div class="idea__item-wrap">' +
                '<div class="idea__item br6">' +
                    '<div class="idea__date">' + val.date_start.format('DD.MM.YYYY HH:mm') + '</div>' +
                    '<div class="idea__img">' +
                        '<img src="' + storage + val.img + '" alt="Здесь могла бы быть картинка :-(" onError="this.src=\'images/error/logo-apple.png\'">' +
                    '</div>' +
                    '<div class="idea__title">' + val.author + '</div>' +
                    '<div class="idea__title">' +
                        '<div class="idea__info">' +
                            '<p>Цена на момент публикации: <span class="bold">' + val.price + '</span></p>' +
                            '<p>Диапазон входа: <span class="bold">' + val.range + '</span></p>' +
                            '<p>Целевая цена: <span class="bold">' + val.target + '</span></p>' +
                            '<p>Потенциал: <span class="bold">' + val.target_p + '</span></p>' +
                            '<p>Стоп-лосс: <span class="bold">' + val.loss + '</span></p>' +
                            '<p>Срок рекомендации: <span class="bold">3 месяца</span></p>' +
                        '</div>' +
                    '</div>' +
                    '<div class="text-center">' +
                        '<a class="idea__more-link" href="#">Подробнее</a>' +
                    '</div>' +
                '</div>' +
            '</div>';
}