let lastScrollTop = 0;
let BODY = document.querySelector('body')

let mediaQuery = x => {
    if (!x.matches) {
        window.onscroll = onScroll;

        function onScroll(e) {
            let top = window.pageYOffset;
            if (lastScrollTop > top) {
                BODY.classList.remove('hide-header')
            } else if (lastScrollTop < top && lastScrollTop > 0) {
                BODY.classList.add('hide-header')
            }
            lastScrollTop = top;
        }
    }
}

let x = window.matchMedia("(max-width: 992px)")
mediaQuery(x)
x.addListener(mediaQuery)
