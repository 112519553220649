window.getBranches = function (type) {
    let wrap = document.querySelector('.contacts-page');
    let loader = document.querySelector('.loader-wrap');

    $.ajax({
        url: '/get-branches',
        dataType: 'json',
        type: 'POST',
        data: {
            type: type, // type: 'all','next','last'
        },
        success: function(json) {
            console.log(json);
            processingContacts(json.data);
            phoneFormat();
           if (wrap){
               wrap.classList.add('loaded');
               loader.hidden = true;
           }
        }
    });
}

function processingContacts(data) {
    let $container = $('div.contacts-wrap');

    $.each(data, function(key, val) {
        let html = makeBranch(key, val);
        $container.append(html);
    });
}

function makeBranch(key, val) {
    let phones = '';
    $.each(val.phones, function(key, phone) {
        phones += '<a class="phone" href="tel:'+ phone +'">'+ phone +'</a>';
    });
    return '<div class="contact-item-wrap-outside">'+
                '<div class="contact-item">'+
                    '<div>'+
                    '<div class="link-wrap">'+
                        '<div>'+ phones + '</div>'+
                        '<a class="email" href="tel:'+ val.emails +'">'+ val.emails +'</a>'+
                    '</div>'+
                    '<div class="info">'+
                        '<p>'+ val.address[0] +'</p>'+
                        '<p>'+ val.address[1] +'</p>'+
                        '<p>'+ val.hours +'</p>'+
                    '</div>'+
                    '<a class="map-link" target="_blank" href="'+ val.maps +'">Посмотреть на карте <i class="icon-left-arrow"></i></a>'+
                    '</div>'+
                '</div>'+
            '</div>';
}