window.getEmployees = function (login) {
    let wrap = document.querySelector('.team-items');
    let loader = document.querySelector('.loader-wrap');

    var $team = $('.team-items'),
        html = '';

    $.ajax({
        url: '/get-employees',
        dataType: 'json',
        data: {
            login: login
        },
        type: 'POST',
        success: function(json) {
            console.log(json);
            let storage = $('input.env').data().storage
            $.each(json.data, function(key, val) {
                html = window.makeEmployee(val,storage);
                $team.append(html);
            });
            wrap.classList.add('loaded');
            loader.hidden = true;
        }
    });
}

window.makeEmployee = function (val,storage) {
    var social = '',
        // description = (val.description) ? val.description.substr(0,val.description.indexOf('<br />')).replace(/(<([^>]+)>)/gi, "") : '';
        description = (val.description) ? val.description.substr(0,val.description.indexOf('<br />')) : '';

    $.each(val.social, function (key, item) {
        social += '<a href="' + item + '" target="_blank"><i class="icon-' + key + '"></i></a>'
    });

    return '<div class="team-item-wrap-outside">' +
            '<div class="team-item">' +
                '<div class="team-item__img">' +
                    '<img src="' + storage + val.photo + '" alt="Здесь могла бы быть картинка :-(" onError="this.src=\'images/error/blank-avatar.png\'">' +
                '</div>' +
                '<div class="team-item__info">' +
                    '<div class="team-item__name">' + val.fio + '</div>' +
                    '<div class="team-item__position">' + val.post + '</div>' +
                    '<div class="team-item__description">' + description + '</div>' +
                    '<div class="bottom-section">' +
                        '<div class="social-links">' + social + '</div>' +
                        '<a class="email" href="mailto:' + val.email+ '">' + val.email + '</a>' +
                    '</div>' +
                '</div>' +
            '</div>' +
        '</div>'
}