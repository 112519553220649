window.getArticles = function (data) {

    $.ajax({
        url: '/get-articles',
        dataType: 'json',
        data: {
            type: data.type // 'review' || 'dictionary' || 'faq' || 'blog'
        },
        type: 'POST',
        success: function(json) {
            console.log(json);
            if (data.type === 'review') {
                let wrap = document.querySelector('.reviews__wrap'),
                    loader = document.querySelector('.loader-wrap');

                processingReview(json.data);

                wrap.classList.add('loaded');
                loader.hidden = true;
            } else if (['dictionary','faq'].includes(data.type)) {
                processingDictionary(json.data,data.type.length);
            } else if (data.type === 'blog') {
                processingBlog(json.data);
            }

        }
    });
}

function processingReview(data) {
    let wrap = $('div.reviews__wrap'),
        storage = $('input.env').data().storage,
        // options = [],
        options = new Map();

    $.each(data, function (key, val) {
        prepareData(val)
        // options.push($('<option>', {value: val.subtype_article, text: val.name }));
        options.set(val.subtype_article, val.name);
        wrap.append(makeReview(val, storage));
    });
    // console.log(options);
    options.forEach((name, key) => {
        $('#subtype_article').append($('<option>', {value: key, text: name }));
    });
    // $('#subtype_article').append(options);
    createSelect();
}

function processingDictionary(data,type_length) {
    let wrap = $('div.dictionary__wrap'),
        href = window.location.hash,
        subtype = {},
        header2 = {
            action: "Действия",
            strategy: "Стратегии",
            metrics: "Финансовые метрики",
            macroeconomics: "Макроэкономика",
            indicators: "Индикаторы",
            instrument: "Инструменты",
            members: "Участники рынка",
        };

    $.each(data, function (key, val) {
        prepareData(val);
        let sub = val.subtype_article.substr(type_length+1);

        if (subtype[sub] === undefined) {
            subtype[sub] = '<div class="dictionary-item__bottom-offset">' +
                '<h2 class="mb20">' + header2[sub] + '</h2>' +
                '<div id="' + sub + '" class="dictionary-item__wrap">';
        }
        subtype[sub] += makeDictionary(val,sub,key);
    });

    $.each(subtype, function(name,html) {
        html += '</div></div>';
        wrap.append(html);
    });

    if (href !== '') {
        scrollTop(href);
    }

    $('.bootstrap_collapse').off().on('click', function() {
        window.location.hash = $(this).get(0).id;
    });
}

function processingBlog(data) {
    let wrap = $('div.blog__wrap'),
        storage = $('input.env').data().storage;

    $.each(data, function (key, val) {
        prepareData(val)
        wrap.append(makeBlog(val,storage));
    });
}

function makeReview(val, storage) {
    let name = val.documents[0].document_name,
        file = val.documents[0].revisions.id;

    return '<div class="reviews__item-outside-wrap">' +
        '<div class="reviews__item" data-id="' + val.id + '" data-name="' + val.name + '" data-type="' + val.type_article + '" data-subtype="' + val.subtype_article + '" data-date="' + val.date_article.toISOString() + '" data-date="' + val.author.id + '">' +
            '<div class="wrap-inside">' +
                '<div class="num-wrap">' +
                    '<div class="num blue">' + val.author.fio + '</div>' +
                '</div>' +
            '<div class="date-wrap">' +
                '<i class="icon-clock"></i>' +
                '<div class="date date-utc">' + val.date_article.format('DD.MM.YYYY HH:mm') + '</div>' +
            '</div>' +
            '<div class="title"><h3>' + val.title_article + '</h3></div>' +
            '<div class="description">' + val.description + '</div>' +
        '</div>' +
        '<a target="_blank" download="' + name + '" href="' + storage + file + '" class="animate-btn btn_blue-border blue-color">Подробнее</a>' +
        // '<a href="' + storage + file + '" data-fancybox data-type="pdf" class="animate-btn btn_blue-border blue-color">Подробнее</a>' +
    '</div>';
}

function makeDictionary(val,sub) {
    return '<div class="card__wrap">' +
        '<div class="card">' +
            '<div class="card-header bootstrap_collapse" id="' + val.name + '">' +
                '<h5 class="mb-0" data-bs-toggle="collapse" data-bs-target="#text-' + val.name + '" aria-expanded="false" aria-controls="text-' + val.name + '">' + val.title_article +
                    '<div class="wrap-icon">' +
                        '<i class="icon-collapse-arrow"></i>' +
                    '</div>' +
                '</h5>' +
            '</div>' +
            '<div id="text-' + val.name + '" class="collapse" aria-labelledby="text-' + val.name + '" data-parent="#' + sub + '">' +
                '<div class="card-body">' + val.description + '</div>' +
            '</div></div></div>'
}

function makeBlog(val,storage) {
    return '<div class="blog__item-wrap">' +
        '<a href="#" class="blog__item">' +
            '<div class="blog__img">' +
                '<img src="' + storage + val.image + '" alt="Здесь могла бы быть картинка :-(" onError="this.src=\'images/error/blog-item.jpg\'">' +
                '<div class="title blue">' + val.name + '</div>' +
            '</div>' +
            '<div class="blog__date-rubrik">' +
                '<div class="blog__author">' + val.author.fio + '</div>' +
                '<div class="date blue">' + val.date_article.format('DD.MM.YYYY') + '</div>' +
            '</div>' +
            '<div class="blog__title">' + val.title_article + '</div>' +
            '<div class="blog__description">' + val.description + '</div>' +
            '<div class="more"><span>Читать подробнее</span></div>' +
        '</a>' +
    '</div>';
}