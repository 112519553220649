$(document).ready(function() {
    let hashes = window.location.hash;
    window.lng = navigator.browserLanguage || navigator.language || navigator.userLanguage;

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    if (typeof actions !== 'undefined' ) {
        $.each(actions, function (key, value) {
            console.log(key);
            eval('window.' + key)(value);
        });
    }

    phoneFormat();

    if (hashes === '#modal1') {
        window.callModal(hashes);
    }
});