window.getStats = function (type) {

    $.ajax({
        url: '/get-stats',
        dataType: 'json',
        type: 'POST',
        success: function(json) {
            console.log(json);
            $.each(json.data, function(key, val) {
                window.updateStats(key, val);
            });
        }
    });
}

window.updateStats = function (key, val) {
    let options = {};

    if (['assets'].includes(key)) {
        options.style = 'currency';
        options.currency = 'USD';
        options.maximumFractionDigits = 0;
    } else if (['success_story'].includes(key)) {
        options.style = 'percent';
    }

    let text = parseFloat(val).toLocaleString(window.lng,options);
    $('.data-' + key).text(text);
}