window.getDocuments = function (type) {
    let wrap = document.querySelector('.certificates-items');
    let loader = document.querySelector('.loader-wrap');
    $.ajax({
        url: '/get-documents',
        dataType: 'json',
        type: 'POST',
        data: {
            type: type, //
        },
        success: function(json) {
            console.log(json);
            processingDocuments(json.data);
            wrap.classList.add('loaded');
            loader.hidden = true;
        }
    });
}

function processingDocuments(data) {
    let $certificates = $('div.certificates-items'),
        $documents = $('div.documentation-items');

    $.each(data, function (key, val) {
        let html,
        storage = $('input.env').data().storage;

        if (val.type_document === 'company_certificates') {
            html = makeCertificates(val, storage);
            $certificates.append(html);
        } else if (val.type_document === 'company_documents') {
            html = makeDocuments(val, storage);
            $documents.append(html);
        }
    });
}

function makeCertificates(val, storage) {
    return '<div class="certificates-item-wrap-outside">' +
        '<div class="certificates-item">' +
            '<a href="' + storage + val.document_revision_id + '" class="link-block" data-fancybox="gallery" data-caption="' + val.description + '">' +
                '<img src="' + storage + val.document_revision_id + '" alt="Здесь могла бы быть картинка :-(" onError="this.src=\'images/error/certificate-item.jpeg\'">' +
            '</a>' +
        '</div></div>'
        ;
}

function makeDocuments(val, storage) {
    return '<div class="documentation-item-wrap-outside">' +
        '<div class="documentation-item document-link-wrap">' +
            '<a download="' + val.revisions.filename + '" href="' + storage + val.document_revision_id + '"></a>' +
            '<div class="img">' +
                '<i class="icon-list"></i>' +
            '</div>' +
            '<div class="title">' + val.document_name + '</div>' +
        '</div></div>'
        ;
}