window.getVar = function () {

    $.ajax({
        url: '/get-variables',
        dataType: 'json',
        type: 'POST',
        data: {
            view: window.location.pathname, // type: 'all','next','last'
        },
        success: function(json) {
            // console.log(json);
            $.each(json.data, function(key, obj) {
                window.varTemplate(key, obj);
            });
        }
    });
}

window.varTemplate = function (key, obj) {
    // console.log(key, obj);
    if ($.trim(obj.html) === '') {
        $.each(obj.text.split('\n\n'), function(i, val) {
            obj.html += '<p><span>' + val + '</span></p>';
        });
    }
    $('.data-' + key).html(obj.html);
}